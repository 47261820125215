/** @jsx jsx */

import { Styled, jsx, Flex, Box, Grid } from "theme-ui"

import { graphql } from 'gatsby'

import Card from '../components/Card'

import Layout from "../components/layout"

import SEO from "../components/seo"

import BasicHero from '../components/BasicHero'


import Button from "../components/button"


/* Icon Group*/
import weldingIcon from '../images/icons/welding-tool-icon.svg'
import carIcon from '../images/icons/car-icon.svg'
import heatIcon from '../images/icons/heat-icon.svg'
import coloredFireIcon from '../images/icons/colored-fire-icon.svg'
import insulationIcon from '../images/icons/insulation-icon.svg'
import ppeIcon from '../images/icons/ppe-icon.svg'


import { Container } from '../components/item'

import SafetySecurity from '../components/SafetySecurity'



export const query = graphql`
  query {
    hero: file(relativePath: { eq: "use-cases-hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    interior: file(relativePath: { eq: "interior-shot.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    welding: file(relativePath: { eq: "welding-blanket-inuse.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    fire: file(relativePath: { eq: "round-fire.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    ppe: file(relativePath: { eq: "ppe.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    protect: file(relativePath: { eq: "protect-environments.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const UseCasePage = ({data}) => (

  <Layout>
    <SEO 
      title="Using your Welding & Fire Blanket"
      description="Nexprotek provides you with use case scenarios and applications for your welding blanket and fire blanket. Our products fit the best of the industry standard, when you get a Nexprotek fire blanket, you get the best."
    />
    
    <BasicHero 
      fluid={data.hero.childImageSharp.fluid}  
      title={"Man Welding Hero Image"} 
      heroTitle={"How to Use Your Fire Blanket"}
      adjacentSectionColor={'white'}
    />
    
    <Box as="section" bg={'white'} color={'black'}>
      <Container>
        <Grid columns={[1, 2, 3]} py={12} sx={{justifyItems: 'center', alignItems: 'space-between'}}>
          <Card
            textColor='black'
            titleColor='black'
            Icon={weldingIcon}
            small
            title="welding blankets" 
            text="Welders from every industry need the same tools to stay safe. Our welding blankets offer protection for anyone working around flames, sparks, and high heat.">
            <Button to="/use-cases/welding-blanket-industry-uses" text="Explore More" />
          </Card>
          <Card
            textColor='black'
            titleColor='black'
            Icon={carIcon}
            small
            title="vehicle insulation" 
            text="Protect yourself and your vehicle. Our vehicle insulation materials reduce road noise, control temperatures, and create a safe barrier between engine and interior." 
          >
            <Button to="/use-cases/vehicle-insulation" text="Explore More" />
          </Card>
          <Card
            textColor='black'
            titleColor='black'
            Icon={heatIcon}
            small
            title="heat barrier" 
            text="Create a buffer for safety and comfort. Draped, installed, or attached to sensitive surfaces, our heat and sound barrier blankets are useful wherever you need thermal or noise control." 
          >
            <Button to="/use-cases/heat-and-sound-barrier" text="Explore More" />
          </Card>
          <Card
            textColor='black'
            titleColor='black'
            Icon={coloredFireIcon}
            small
            title="fire extinguisher" 
            text="Your first line of defense in emergencies such as kitchen, fireplace, and electrical fires. Fire blankets fight flames without the use of harmful chemicals." 
          >
            <Button to="/use-cases/fire-extinguisher" text="Explore More" />
          </Card>
          <Card
            textColor='black'
            titleColor='black'
            Icon={ppeIcon}
            small
            title="Personal Protection Equipment" 
            text="Your first line of defense in emergencies such as kitchen, fireplace, and electrical fires. Fire blankets fight flames without the use of harmful chemicals." 
          >
            <Button to="/use-cases/personal-protection-equipment-ppe" text="Explore More" />
          </Card>
          <Card
            textColor='black'
            titleColor='black'
            Icon={insulationIcon}
            small
            title="insulation" 
            text="Our fire and welding blankets can be used as insulation to guarantee maximum heat retention in any space." 
          >
            <Button to="/use-cases/insulation" text="Explore More" />
          </Card>
        </Grid>
      </Container>
    </Box>

    <hr sx={{
      borderTop: '3px solid #bbb',
      width: '75vw',
    }} />

    <Box as="section" my={7}>
      <Container sx={{
        textAlign: 'center',
      }}>
        <Styled.h2 as="span" sx={{
          textAlign: 'center',
          fontWeight: 'bold'
        }}>Safety First</Styled.h2>
        <Flex width='100%' sx={{
          py: '40px',
          justifyContent: 'space-between',
          flexDirection: ['column', 'row'],
          alignItems: ['center', 'flex-start'],
        }}>
        <Card
            textColor='black'
            titleColor='black'
            fluidImg={data.fire.childImageSharp.fluid}
            title="extinguish fires" 
            text="Smother small fires at the source before they grow. Our fiberglass-and-silicone technology cuts off a fire’s oxygen supply, extinguishing it quickly. " 
          />
          <Card
            textColor='black'
            titleColor='black'
            roundImage
            fluidImg={data.ppe.childImageSharp.fluid}
            title="personal protection equipment" 
            text="When you’re working with fire on a daily basis, protect what’s most important - you. Fire-resistant gear is a lifesaver for first responders and welders on high-risk projects. " 
          />
          <Card
            textColor='black'
            titleColor='black'
            roundImage
            fluidImg={data.protect.childImageSharp.fluid}
            title="protect work environments" 
            text="Fire blankets can save lives in any workplace. Whether yours is a machine shop or an office suite, they can keep your work environment safe and up to code. " 
          />
          </Flex>
      </Container>
    </Box>

    
    <SafetySecurity />
    
  
  </Layout>
)

export default UseCasePage
